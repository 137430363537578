//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from "../../app.config";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Students from "../components/personal/Students";
import MenuIcon from "@/components/icons/MenuIcon";

export default {
  name: "MainHeader",
  components: {MenuIcon, Students},
  data: function () {
    return {
      config,
    }
  },
  methods: {
    ...mapActions([
      'logout',
    ]),
    ...mapMutations([
      'toggleIsUserModal',
      'toggleIsProfileModal',
    ]),
    checkToggle(event) {
      if (this.$route.path.includes('training')) return;
      if (this.$store.state.isProfileModal) 
        this.toggleIsProfileModal();
      event.stopPropagation();
      this.toggleIsUserModal();
    }
  },
  computed: {
    isPersonal() {
      return this.$router.currentRoute.name === 'personal'
    },

    workerName() {
      if (this.worker.surName) {
        return this.worker.surName + ' ' + this.worker.firstName.charAt(0) + '. ' + this.worker.middleName.charAt(0) + '.'
      }

      return '';
    },
    isTraining() {
      return this.$route.path.includes('trainings');
    },
    ...mapState([
      'isUserModal',
      'currentUser',
      'worker',
      'token',
    ]),
    ...mapGetters([
      'isIdAuthMethod',
      'isPhoneAuthMethod',
    ])
  }
}
